.logo:hover {
  color: darkred;
}

.version {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  opacity: 0.5;
}