@media (max-width: 640px) {
  .mindmap {
    visibility: hidden;
  }
}

@media (min-width: 641px) {
  .mindmap-mobile {
    visibility: hidden;
  }
}

.me {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  border-radius: 80%;
  border-bottom: 1px solid grey;

  position: relative;
  top: 200px;
  left: 50%;
  transform: translate(-50%, 0);
}

.role {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  position: absolute;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 1px solid grey;
}

.role:hover {
  background-color: aliceblue;
}

.svg-container {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%, 0);
}

.svg-element {
  width: 600px;
  height: 500px;
}