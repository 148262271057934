@media (max-width: 640px) {
  .mindmap {
    visibility: hidden;
  }
}

@media (min-width: 641px) {
  .mindmap-mobile {
    visibility: hidden;
  }
}

.me {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  border-radius: 80%;
  border-bottom: 1px solid grey;

  position: relative;
  top: 200px;
  left: 50%;
  transform: translate(-50%, 0);
}

.task {
  text-align: center;
  position: absolute;
  border-radius: 5px;
  color: white;
  border-bottom: 1px solid white;
}

.candidate-task {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: shine 2s infinite;
    opacity: 1;
    font-weight: bolder;
  }

  @keyframes shine {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

.mindmap-center:hover {
  color: grey;
}

.svg-container {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%, 0);
}

.svg-element {
  width: 600px;
  height: 500px;
}

.right {
  text-align: right;
  padding-right: 10px;
}

.left {
  text-align: left;
  padding-left: 10px;
}