.new-role-container {
  background-color: rgb(233, 187, 60);
  border: 1px solid black;
  border-radius: 10px;
  width: 90%;
  margin: 5px auto;
  padding: 5px;
  min-height: 30px;
}

.new-role-container-edit {
  background-color: lightcoral;
}

.new-role-container-confirm {
  background-color: greenyellow;
}

.new-role-container:hover {
  cursor: pointer;
}

.new-role-container-form {
  padding: 0;
}

.new-role-edit-input {
  border: none;
  outline: none;
  background-color: inherit;
  font-size: inherit;
  text-align: center;
  padding: 0;
  width: 100%;
}