.gyst-categorizer-container {
  border: 1px solid black;
  border-radius: 5px;
  width: 90%;
  max-width: 350px;
  margin: 2px auto;

  
}

.gyst-categorizer-container button {
  width: 100px;
  margin-bottom: 2px;
}