.auth-page-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  text-align: center;
}


@media (max-width: 840px) {
  .auth-page-container {
    flex-direction: column; /* Vertical alignment on small screens */
    align-items: center;
    width: 60%;
    margin: auto;
  }

  .hr-container {
    width: 100%;
  }
}

@media (min-width: 841px) {
  .auth-form {
    width: 42%;
  }
}

.auth-form h4 {
  margin-bottom: 15px;
}

.auth-field {
  margin: auto;
}

.auth-field div {
  min-width: 260px;
}